.nav-tabs {
  .nav-link {
    font-size: .750em;
  }
}


.ant-tabs .ant-tabs-large-bar .ant-tabs-tab {
  padding: 7px 16px;
  font-size: .750rem;
}

.ant-card-type-inner .ant-card-head-title {
  font-size: .750rem;
}
.ant-tabs-nav-wrap {
  margin-bottom: 4px;
}

.uptime-info {
  font-size: .750rem;
}