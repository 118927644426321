.rce-table {

  thead {
    background: #dedede;
    th {
      font-size: .750em;
      padding: .400em;

      &.filter {
        position:relative;

        select {
          font-size: .750rem;
          height: calc(2rem)
        }

        &:after {
          font-family: 'rcefoto';
          content: '\f0b0';
          position: absolute;
          right: 10px;
          top: 5px;
        }

        label {
          display:none;
        }
      }

      &.open {
        label {
          display:block;
        }
      }

    }
  }

  tbody {
    td {
      font-size: .750em;
      padding: .400em;
    }
  }


}

.page-item.active .page-link {
    background-color: #fe0000;
    border-color: #fe0000;

  }

  .pagination {
    float: right;
    font-size: .750em;
  }