@import 'font/fontello/css/animation.css';
@import 'font/fontello/css/rcefoto.css';
@import '~bootstrap/scss/bootstrap';
@import '~antd/dist/antd.min.css';

@import "components/_tablestores.scss";
@import 'common/_tables.scss';
@import 'common/_common.scss';

body {
  /*background-color: #282c34;*/
  font-size: 14px;
}

.app-header {
   padding: 5px;
   background: #000000;
   position:fixed;
   width: 100%;
   color: $white;
   z-index: 8;

  .logo {
    width: 80%;
    display:block;
  }
}

#section-page {
  padding-top: 90px;
  z-index: 2;
}

table {
  td {
    font-size: 13px;
  }
}
.errors {}



